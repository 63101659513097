<template>
  <container ref="container" class="joinUs" @error="init">
    <!-- 新闻 -->
    

    <!-- 导航 -->
    <div class="joinUs-nav">
      <!-- 导航-元素 -->
      <div
        class="joinUs-nav-item"
        :class="{ 'joinUs-nav-item_active': index == nav.index }"
        v-for="(item, index) in nav.list"
        :key="index"
        @click="navChange(index)"
      >
        {{ item.name }}
      </div>
    </div>

    <!-- 内容 -->
    <container ref="joinUsContent" @error="navChange(nav.index)">
      <div class="joinUs-content" v-html="detail.content"></div>
    </container>
  </container>
</template>

<script>
export default {
  name: "joinUs-index",
  components: {},
  data() {
    return {
      news: {
        cover: "",
        list: [],
      },

      nav: {
        list: [],
        index: 0,
      },

      detail: {},
    };
  },
  mounted() {
    // 初始化数据
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const [news, nav] = await Promise.all([
          this.$axios("/api/mainstation/joinDynamic", {
            params: {
              type: 1,
              num: 9,
            },
          }),
          this.$axios("/api/mainstation/dynamicNav"),
        ]);

        // 设置数据
        this.news.cover = news.data.merpic;
        this.news.list = news.data.newslist;
        this.nav.list = nav.data;

        // 变更导航和数据
        this.navChange(0);

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },

    /**
     * 导航更改
     */
    async navChange(index) {
      try {
        // 变更导航
        this.nav.index = index;

        // 设置状态
        this.$refs.joinUsContent.status("init", {
          height: "100vh",
        });

        // 获取数据
        const result = await this.$axios("/api/mainstation/dynamicContent", {
          params: {
            type: this.nav.list[index].id,
          },
        });

        // 设置数据
        this.detail = result.data;

        // 设置状态
        this.$refs.joinUsContent.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.joinUsContent.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 加入我们
  .joinUs {
    // 加入我们-新闻
    &-news {
      width: 1200px;
      margin: auto;
      margin-top: 45px;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #eaeaea;

      // 加入我们-新闻-顶部
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
          font-size: 18px;
          color: #fff;
          background-color: #ed207b;
          width: 160px;
          height: 50px;
          line-height: 50px;
          text-align: center;
        }

        &-more {
          font-size: 18px;
          color: #707070;
          padding: 0 25px;
        }
      }

      // 详情
      &-detail {
        display: flex;
        padding-top: 15px;
        padding-bottom: 50px;
      }

      &-cover {
        width: 370px;
        height: 288px;
        margin: 0 30px;

        img {
          @include cover;
        }
      }

      // 列表
      &-list {
        flex: 1;
        width: 0;
        padding-right: 25px;
      }

      // 元素
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 32px;

        // 元素-标题
        &-title {
          color: #292929;
          font-size: 16px;
          width: 0;
          flex: 1;

          @include ellipsis;
        }

        // 元素-日期
        &-date {
          color: #bababa;
          font-size: 16px;
        }
      }
    }

    // 导航
    &-nav {
      width: 1200px;
      margin: auto;
      height: 80px;
      border-radius: 10px;
      border: 1px solid #bfbfbf;
      display: flex;
      margin-top: 30px;
      overflow: hidden;

      // 导航-元素
      &-item {
        color: #282828;
        flex: 1;
        width: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;

        &:not(:last-of-type) {
          border-right: 1px solid #bfbfbf;
        }

        &_active {
          background-color: #ed207b;
          color: #fff;
        }
      }
    }

    // 内容
    &-content {
      width: 1200px;
      margin: 60px auto;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 加入我们
  .joinUs {
    // 加入我们-新闻
    &-news {
      margin: auto rpx(30);
      margin-top: rpx(40);
      border-radius: rpx(20);
      overflow: hidden;
      border: rpx(2) solid #eaeaea;

      // 加入我们-新闻-顶部
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
          font-size: rpx(24);
          color: #fff;
          background-color: #ed207b;
          width: rpx(150);
          height: rpx(50);
          line-height: rpx(50);
          text-align: center;
        }

        &-more {
          font-size: rpx(28);
          color: #707070;
        padding: 0 rpx(40);
        }
      }

      // 详情
      &-detail {
        display: flex;
        padding-top: rpx(20);
        padding-bottom: rpx(20);
      }

      &-cover {
        display: none;
      }

      // 列表
      &-list {
        flex: 1;
        width: 0;
        padding: 0 rpx(15);
      }

      // 元素
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: rpx(40);

        // 元素-标题
        &-title {
          color: #292929;
          font-size: rpx(24);
          width: 0;
          flex: 1;

          @include ellipsis;
        }

        // 元素-日期
        &-date {
          color: #bababa;
          font-size: rpx(24);
        }
      }
    }

    // 导航
    &-nav {
      margin: auto rpx(30);
      height: rpx(80);
      border-radius: rpx(15);
      border: rpx(2) solid #bfbfbf;
      display: flex;
      margin-top: 30px;
      overflow-x: scroll;

      // 导航-元素
      &-item {
        color: #282828;
        white-space: nowrap;
        padding: 0 rpx(30);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: rpx(24);
        scroll-behavior: smooth;

        &:not(:last-of-type) {
          border-right: rpx(2) solid #bfbfbf;
        }

        &_active {
          background-color: #ed207b;
          color: #fff;
        }
      }
    }

    // 内容
    &-content {
      margin: rpx(60) rpx(30);
    }
  }
}
</style>
